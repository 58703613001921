import useLocations from "@/hooks/api-hooks/useLocations"
import { useTypedSelector } from "@/store/index"
import { getSelectedObjectById } from "@/utils/util-functions"
import { useMemo } from "react"
import { useCookies } from "react-cookie"

export default function useSelectedLocation() {
  const [cookies] = useCookies(['nextme_mobile_location'])

  const locationIdCookie = useMemo(() => {
    return cookies?.nextme_mobile_location
  }, [cookies])

  const { locationId } = useTypedSelector((state) => state.selected)

  const { locations, mutateLocations } = useLocations()

  const locationIdWithCookie = useMemo(() => {
    return locationIdCookie && locationIdCookie.length === 36 ? locationIdCookie : locationId
  }, [locationId, locationIdCookie])

  const selectedLocation = useMemo(() => {
    return getSelectedObjectById(locationIdWithCookie, locations)
  }, [locationIdWithCookie, locations])

  return useMemo(
    () => ({
      locationId: locationIdWithCookie,
      selectedLocation,
      mutateLocations,
    }),
    [selectedLocation, mutateLocations]
  )
}
