import { setIsUsingCustomQueueSelector } from '@/store/slices/pages/manageSlice';
import { syncSciSettings } from '@/store/slices/pages/settingsSlice';
import { useTypedSelector } from "../store"
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import useCustomFields from './api-hooks/useCustomFields';
import { isCfPossibleForLocation } from '@/utils/custom-field-utils';
import { getSharedSci, shouldSyncSciSettings } from '@/utils/sci-utils'
import useSelfCheckIns from './api-hooks/useSelfCheckIns';
import useLocations from './api-hooks/useLocations'; 
import { getSelectedObjectById } from '@/utils/util-functions';
import { 
  setCustomQueueSelectOptions, 
  setIsUsingBothQueueSelectors, 
  setIsUsingCustomQueueSelector as setQueueSelectBoolean, 
  setStandardQueueSelectOptions 
} from '@/store/slices/queueSelectSlice';
import {
  getCustomQueueSelectOptions, 
  getStandardQueueSelectOptions,
  getAllStandardQueueSelectOptions,
} from '@/utils/queue-utils'
import useWaitTimesByLocationId from './api-hooks/useWaitTimesByLocationId';
import useSelectedLocation from './api-selected-hooks/useSelectedLocation';


const useIsUsingCustomQueueSelector = (isSci: boolean) => {
  const dispatch = useDispatch()
  const { isUsingSharedSettings, hasOnlyOneLocation, isUsingCustomQueueSelector } = useTypedSelector((state) => state.pages.manage)
  const { customFields } = useCustomFields()
  const { selfCheckIns } = useSelfCheckIns()
  const { locationId, selectedLocation } = useSelectedLocation()
  const { waitTimes } = useWaitTimesByLocationId(locationId)

  const queueSelectFields = useMemo(() => customFields
    .filter(cf => isCfPossibleForLocation(
      cf,
      isUsingSharedSettings,
      hasOnlyOneLocation,
      locationId,
      isSci
    ))
    .filter(cf => cf.type === 'queueselect'),
    [locationId, isUsingSharedSettings, hasOnlyOneLocation, customFields]
  )

  const customQueueCFId = useMemo(() => queueSelectFields[0]?.id, [queueSelectFields])
  const queueSelectorFieldExists = useMemo(() => queueSelectFields.length > 0, [queueSelectFields])
  const sharedSci = useMemo(() => getSharedSci(selfCheckIns), [selfCheckIns])
  const shouldSync = useMemo(() => shouldSyncSciSettings(sharedSci, isUsingCustomQueueSelector, customQueueCFId), [sharedSci, isUsingCustomQueueSelector, customQueueCFId])
  const { standardQueueOptions: storedStandardQueueOptions } = useTypedSelector((state) => state.queueSelect)

  const customQueueOptions = useMemo(() => {
    if (queueSelectFields && waitTimes) {
      return getCustomQueueSelectOptions(queueSelectFields, waitTimes)
    }
  }, [queueSelectFields, waitTimes])

  const standardQueueOptions = useMemo(() => {
    return getStandardQueueSelectOptions(selectedLocation?.enabledQueues, waitTimes, customQueueOptions)
  }, [selectedLocation, waitTimes, customQueueOptions])

  const allStandardQueueOptions = useMemo(() => {
    return getAllStandardQueueSelectOptions(selectedLocation?.enabledQueues, waitTimes)
  }, [selectedLocation, waitTimes])

  const shouldUseBothQueueSelectors = useMemo(() => {
    return isUsingCustomQueueSelector && standardQueueOptions?.length > 0
  }, [standardQueueOptions, isUsingCustomQueueSelector])

  const hydratedSci = useMemo(() => {
    return {
      ...sharedSci,
      geofence: {
        addressId: selectedLocation?.address?.id || "",
        radiusMi: 1,
        isEnabled: false,
      }
    }
  }, [sharedSci, selectedLocation])

  useEffect(() => {
    if (queueSelectorFieldExists !== isUsingCustomQueueSelector) {
      dispatch(setIsUsingCustomQueueSelector(queueSelectorFieldExists))
      dispatch(setQueueSelectBoolean(queueSelectorFieldExists))
    }
    if (selectedLocation && queueSelectorFieldExists && shouldSync && hydratedSci?.geofence?.addressId) {
      dispatch(syncSciSettings({
        sci: hydratedSci,
        isUsingCustomQueueSelector: queueSelectorFieldExists,
        customQueueCFId
      }))
    }
    
  }, [queueSelectorFieldExists, queueSelectFields, shouldSync, hydratedSci, selectedLocation, customQueueCFId])

  useEffect(() => {
    dispatch(setCustomQueueSelectOptions(customQueueOptions))
  }, [customQueueOptions])

  useEffect(() => {
    dispatch(setStandardQueueSelectOptions(standardQueueOptions))
  }, [standardQueueOptions])

  useEffect(() => {
    dispatch(setIsUsingBothQueueSelectors(shouldUseBothQueueSelectors))
  }, [shouldUseBothQueueSelectors])

  
  
  return { 
    isUsingCustomQueueSelector: queueSelectorFieldExists,
    allStandardQueueOptions,
    customQueueSelectCfId: customQueueCFId,
  }

}

export default useIsUsingCustomQueueSelector